<template>
    <div v-if="loaded">
        <div class="alert alert-info">
            <h6>Info facturation</h6>
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <th>Usage</th>
                        <td>{{usage}}$</td>
                    </tr>
                    <tr>
                        <th>Forecast</th>
                        <td>{{forecast}}$</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else>
        <p>Chargement...</p>
    </div>
</template>

<script setup>

    import { onMounted, ref } from 'vue';
    import axios from 'axios';

    let usage = ref(0);
    let forecast = ref(0);
    let loaded = ref(false);

    onMounted(() => {

        axios.get("/api/costs/usage")
            .then( answer => {
                usage.value = Number.parseFloat(answer.data.data[0].Total.UnblendedCost.Amount).toFixed(2);
                return axios.get("/api/costs/forecast")
            })
            .then( answer => {
                forecast.value = Number.parseFloat(answer.data.data[0].MeanValue).toFixed(2);
                loaded.value = true;
            })

    })

</script>